.ActionBar {
	display: flex;
	justify-content: space-between;
}
  
.ActionBar__Submit {
	display: flex;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
  
@keyframes fadeOut {
	from {
	  opacity: 1;
	}
  
	to {
	  opacity: 0;
	}
}
  