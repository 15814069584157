.UploadGif__Btn {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	background: none;
	outline: none;
	border: 0;
  }
  
  .UploadGif__Btn:hover {
	background-color: rgba(27, 149, 224, 0.1);
  }
  
  .UploadGif__Btn:disabled {
	cursor: not-allowed;
	opacity: 0.6;
  }
  
  .GifGrid {
	position: fixed;
	display: flex;
	top: 0;
	left: 0;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 2;
	width: 100%;
	height: 100%;
	animation: fadeIn 0.35s ease-in forwards;
  }
  
  .GifGrid__Wrapper {
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	overflow: hidden;
	background-color: white;
  }
  
  .GifGrid__BackBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	background: none;
	outline: none;
	cursor: pointer;
	border: 0;
	border-radius: 50%;
	height: 39px;
	width: 39px;
  }
  
  .GifGrid__BackBtn:hover {
	background-color: rgba(27, 149, 224, 0.1);
  }
  
  .GifGrid__Header {
	display: flex;
	padding: 0px 10px;
	align-items: center;
	border-bottom: 1px solid #e6e6e6;
	height: 50px;
  }
  
  .GifGrid__Content {
	box-sizing: border-box;
	padding: 10px;
	height: 600px;
	overflow: auto;
  }
  
  @media only screen and (max-width: 650px) {
	.GifGrid__Wrapper {
	  height: 100%;
	  border-radius: 0;
	}
	.GifGrid__Content {
	  height: 100%;
	}
  }
  