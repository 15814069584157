.PreviewMedia_Grid {
	display: flex;
	flex-wrap: wrap;
	max-width: 500px;
	margin: 10px 0;
  }
  
  .PreviewMedia {
	width: 100%;
	font-size: 15px;
	position: relative;
	border-radius: 10px;
	overflow: hidden;
	outline-style: none;
	animation: fadeIn 0.35s ease-in forwards;
	padding-top: 56.25%;
	min-width: 240px;
  }
  
  .PreviewMedia__Wrapper {
	flex: 1;
	min-width: 240px;
  }
  
  .PreviewMedia__Wrapper:nth-child(even) {
	margin-left: 10px;
  }
  
  .PreviewMedia__Wrapper:nth-child(n + 2) {
	max-width: 245px;
  }
  
  .PreviewMedia__Wrapper:nth-child(n + 3) {
	margin-top: 10px;
  }
  
  .PreviewMedia img {
		transform: translate(-50%, -50%);
		left: 50%;
		top: 50%;
		width: 100%;
		display: block;
		position: absolute;
  }
  .PreviewMedia video {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
  }
  