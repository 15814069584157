.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
	width: 98%;
	margin-left: 0px;
	margin-right: 0px;
}

.react-datepicker-popper {
    z-index: 9999 !important;
}
