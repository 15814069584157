.Editor {
	display: block;
	font-size: 19px;
	position: relative;
	width: 100%;
	max-width: 100%;
	overflow: hidden;
	min-height: 48px;
	text-align: left;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	box-sizing: border-box;
	border: solid 1px gainsboro;
	border-radius: 6px;
	color: black;
	margin-bottom: 5px;
}
  
.Editor__Highlight {
	color: rgb(27, 149, 224);
}
  
.Editor__OverLimit {
	background-color: #fd9bb0;
}
  
.public-DraftEditorPlaceholder-root {
	z-index: -1;
	position: absolute;
}
  