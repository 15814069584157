.UploadMediaBar__Input {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	height: 40px;
	width: 40px;
  }
  
  .UploadMediaBar__Input:hover {
	background-color: rgba(27, 149, 224, 0.1);
  }
  
  .UploadMediaBar__Input[data-disabled="true"] {
	cursor: not-allowed;
	opacity: 0.6;
  }
  
  .UploadMediaBar__Input input {
	width: 0;
	height: 0;
  }
  