.EmojiPicker {
	position: relative;
  }
  
  .EmojiPicker__Wrapper {
	position: absolute;
	top: 100%;
	left: 0%;
	z-index: 2;
	animation: fadeIn 0.15s ease-in forwards;
  }
  
  .EmojiPicker__Btn {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	background: none;
	outline: none;
	border: 0;
  }
  
  .EmojiPicker__Btn:hover {
	background-color: rgba(27, 149, 224, 0.1);
  }
  
  .EmojiPicker__Btn:disabled {
	cursor: not-allowed;
	opacity: 0.6;
  }
  